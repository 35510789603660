.Shell-children {
  /* padding-top: 2rem; */
  /* min-width: 960px; */
  min-width: 1080px; 
  /* min-height: 100vh; */
}

.main-content,
.scrollable-sidebar {
  overflow-y: auto;
  -ms-overflow-style: none; 
}

.main-content,
.scrollable-sidebar {
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.main-content::-webkit-scrollbar,
.scrollable-sidebar::-webkit-scrollbar {
  display: none; 
}

